/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import "../../assests/styles/request-lab-service.css";
import { useSelector } from "react-redux";
import {
  Checkbox,
  RadioInput,
} from "components/InputComponents/InputComponents";
import { StepProps } from "./stepsProps";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
import { useDispatch } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import { IoArrowBack } from "react-icons/io5";
const ARTHivStatus: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const artData = useSelector(
    (state: any) => state.hivCare.Antiretroviral_Therapy
  );
  console.log("viralLoad", artData.viral_load)
  const [cd4Count, setCD4Count] = useState<string>(artData.cd4_count?artData.cd4_count:'');
  const [viralLoad, setViralLoad] = useState<string>(artData.viral_load?artData.viral_load:'');
  const [healthIssues, setHealthIssues] = useState<string[]>(artData.health_issues?artData.health_issues:[]);
  const [] = useState("");
  const dispatch = useDispatch();
  const { GetArtTreatment } = useHivCare();
  const healthQuestions = [
    {
      question: "Current CD4 Count",
      options: [
        { id: 1, text: "Less than 200" },
        { id: 2, text: "200 - 349" },
        { id: 3, text: "350 - 499" },
        { id: 4, text: "500 or more" },
      ],
    },
    {
      question: "Recent HIV Viral Load",
      options: [
        { id: 5, text: "Undetectable" },
        { id: 6, text: "Less than 1000 copies/mL" },
        { id: 7, text: "1000 copies/mL or more" },
      ],
    },
    {
      question: "Recent Health Issues or Concerns? (Select all that apply)",
      options: [
        { value: "none", label: "None" },
        { value: "fever_or_chills", label: "Fever or Chills" },
        { value: "persistent_cough", label: "Persistent Cough" },
        { value: "unexplained_weight_loss", label: "Unexplained Weight Loss" },
        { value: "0ther", label: "0ther" },
      ],
    },
  ];

  GetArtTreatment.artHivStatus(dispatch, cd4Count, viralLoad, healthIssues);

  var displayError = false;
  const setError = () => {
    displayError = false;
    if (cd4Count ===null) {
      displayError = true;
      return "Please select a Current CD4 Count";
    } else if (viralLoad === null) {
      displayError = true;
      return "Please select atleast one Recent HIV Viral Load";
    } else if (healthIssues.length === 0)  {
      displayError = true;
      return "Please provide a Health Issues";
    }

    displayError = false;
    return;
  };
  const errorMessage = setError()
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          {/* <img src={Arrowleft} alt="arrow-left" /> */}
          <IoArrowBack />
          Forgot something?
        </div>
      </div>
      <h3 className="heading3">Current HIV Status</h3>

      <div>
        <RadioInput
          options={
            (healthQuestions.find(
              (question) => question.question === "Current CD4 Count"
            )?.options as any) || null
          }
          title="Current CD4 Count:"
          setData={setCD4Count}
          selected_option={cd4Count as string}
        />
      </div>
      <div>
        <RadioInput
          options={
            (healthQuestions.find(
              (question) => question.question === "Recent HIV Viral Load"
            )?.options as any) || null
          }
          title="Recent HIV Viral Load"
          setData={setViralLoad}
          selected_option={viralLoad as string}
        /> 
      </div>

      <div>
        <Checkbox
          options={
            (healthQuestions.find(
              (question) =>
                question.question ===
                "Recent Health Issues or Concerns? (Select all that apply)"
            )?.options as any) || null
          }
          title="Recent Health Issues or Concerns? (Select all that apply)"
          seData={setHealthIssues}
          store_checked_values={healthIssues as string[]}
        />
      </div>

      <div className="flex gap-2">
        <button type="submit" className="submit-button" onClick={onPrevious}>
        Back
        </button>
        <button type="submit" className="submit-button" onClick={onNext} disabled={errorMessage? true : false}>
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default ARTHivStatus;
