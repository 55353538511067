
import React, { useState, useEffect } from "react";
import NavBack from "../../assests/icons/arrowleftblack.svg";
import "../../assests/styles/registration.css";
// import HivCareRegLogo from "../../assests/icons/netlifRegLogo.svg";
import HivCareRegLogo from"assets/headerImages/Logo/Landscape/SVG Logos/HIVI CARE Landscape Logos_Black.svg";
import { useNavigate } from "react-router-dom";
import PhoneInputComponent from "components/shared/PhoneInputComponent";
import Loader from "components/shared/Loader";

interface Register1prop {
  onNext: () => void;
  handleChange: (e: any) => void;
  registerValues: any;
  errors: any;
  validateRegister: () => boolean | undefined;
}

const Register1: React.FC<Register1prop> = ({
  onNext,
  handleChange,
  registerValues,
  errors,
  validateRegister,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true); // Default is true to hide error initially
  const navigate = useNavigate();

  // Function to check if phone number is valid (e.g., 12 digits for Uganda)
  const validatePhoneNumber = (phone: string): boolean => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.length === 12;
  };

  // Effect to validate form when values change, including phone number
  useEffect(() => {
    setIsFormValid(!!validateRegister() && isPhoneValid);
  }, [registerValues, errors, isPhoneValid, validateRegister]);

  // Handle phone number change to update validity
  const handlePhoneChange = (phoneValue: string) => {
    handleChange({ target: { name: "phone_number", value: phoneValue } });
    setIsPhoneValid(validatePhoneNumber(phoneValue));
  };

  const handleContinue = async () => {
    if (isFormValid) {
      setIsLoading(true);
      onNext();
      setIsLoading(false);
    }
  };

  return (
    <div className="registration-container">
      <div className="registration-form">
        <div className="nav-back cursor-pointer" onClick={() => navigate("/SignIn")}>
          <img src={NavBack} alt="nav back" /> Back
        </div>
        <h2>Sign Up for Hiv Care</h2>
        <p>Access healthcare anywhere. Connect, schedule, and manage your health. Create your account today!</p>
        
        <form>
          <div>
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={registerValues.first_name}
              onChange={handleChange}
              id="first_name"
            />
          </div>

          <div>
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={registerValues.last_name}
              onChange={handleChange}
              id="last_name"
            />
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <PhoneInputComponent
              name="phone_number"
              value={registerValues.phone_number}
              onChange={handlePhoneChange}
              error={isPhoneValid ? "" : "Phone number must be 12 digits"} // Conditionally render error message
            />
          </div>

          <button
            type="button"
            onClick={handleContinue}
            disabled={!isFormValid || isLoading} 
            className={!isFormValid ? "inactive" : "active"}
          >
            {isLoading ? (
              <Loader />
            ) : (
              "Continue"
            )}
          </button>
        </form>

        <p className="already cursor-pointer" onClick={() => navigate("/SignIn")}>
          Already have an account? <span className="">Sign in</span>
        </p>

        <div className="cop-right">
          <ul>
            <li><div>© 2024 Hiv Care</div></li>
            <li><div>About Us</div></li>
            <li><div>Terms of Service</div></li>
            <li><div>Privacy Policy</div></li>
          </ul>
        </div>
      </div>
      
      <div className="registration-image">
        <div className="overlay">
          <h3>See your health care anytime, anywhere</h3>
          <img src={HivCareRegLogo} alt="reg icon" />
        </div>
      </div>
    </div>
  );
};

export default Register1;

