import React, { useState } from "react";

import { StepProps } from "./stepsProps";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
import useRequestLabService from "hooks/useRequestLabService";
import { IoArrowBack } from "react-icons/io5";
const RequestLabservice6: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const [getLabResultsFrom, setgetLabResultsFrom] = useState<string | null>(
    null
  );
const {setGetResultsFrom,dispatch} =useRequestLabService()
  const handleOptionClick = (option: string) => {
    setgetLabResultsFrom(option);
    dispatch(setGetResultsFrom(option));
  };
  if (getLabResultsFrom !== null) {
    onNext();
  }
  console.log(getLabResultsFrom);
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          {/* <img src={Arrowleft} alt="arrow-left" /> */}
          <IoArrowBack />
          Forgot something ?
        </div>
      </div>

      <div className="heading2">How do you want to get your lab results?</div>

      {/* <p>Select your preferred healthcare provider for sample collection, 
    and we will schedule a visit at a date and time convenient for them. Please note that the available time slots may vary depending on the provider's schedule</p> */}

      <div className="sel-test">
        {["Email", "Phone", "WhatsApp", "In-person"].map((option, index) => {
          return (
            <button key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RequestLabservice6;
