import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import profileIcon from "../../assests/icons/User.svg";
import logo from '../../../src/assets/headerImages/Logo/Landscape/SVG Logos/HIVI CARE Landscape Logos_White copy.svg';
import useStates from "hooks/useStates";
import localStorageService from "services/localStorageService";
import RequestService from "services/requestService";
import "../../assets/TopHeader.css";
import Loader from "components/shared/Loader";

const Header: React.FC = () => {
  const { first_name } = useStates();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLogout, setShowLogout] = useState<boolean>(false); // State to toggle logout visibility

  const handleLogout = async () => {
    setIsLoading(true);
    await localStorageService.clearStorage('user');

    setTimeout(() => {
      navigate('/SignIn'); // Redirect to login page after sign-out
      setIsLoading(false);
    }, 5000);
    
    await RequestService.getMethod('logout'); // Assuming this is an API call for logout
    setIsLoading(false);
  };

  const toggleLogout = () => {
    setShowLogout(!showLogout); // Toggle the visibility of logout
  };

  return (
    <header className="header-background">
      <div className="header-content">
        <div className="logo-container" onClick={() => navigate("/dashboard")}>
          <img src={logo} alt="Logo" className="logo" />
        </div>

        <div className="profile-container">
          <img 
            src={profileIcon} 
            alt="Profile" 
            className="profile-image" 
            onClick={toggleLogout} // Toggle on profile click
          />
          <span className="profile-name">{first_name || ""}</span>

          {/* Conditionally render the logout button below the profile */}
          {showLogout && (
            <div className="logout-container">
              <button onClick={handleLogout} className="sign-out-button" disabled={isLoading}>
                {isLoading ? <Loader /> : 'Sign Out'}
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;

