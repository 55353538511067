import React, { useState } from "react";

import { StepProps } from "./stepsProps";
import { useSelector } from "react-redux";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
import useRequestLabService from "hooks/useRequestLabService";
import { IoArrowBack } from "react-icons/io5";

type GetResultsFromTypes ={
    options:[] |null
    title:string | null
    selectedoptions:string | null
}
const GetResultsFrom: React.FC<StepProps & GetResultsFromTypes> = ({ onNext, onPrevious,options,title,selectedoptions }) => {
  const getResultsFrom = useSelector(
    (state: any) => state.requestLabService.getResultsFrom

  );
  console.log(getResultsFrom)
  const [getLabResultsFrom, setgetLabResultsFrom] = useState<string | null>(
  selectedoptions|| null
  );
const {setGetResultsFrom,dispatch} =useRequestLabService()
  const handleOptionClick = (option: string) => {
    setgetLabResultsFrom(option);
    dispatch(setGetResultsFrom(option));
    if (onNext) {
      onNext();
    }
  };
 
  console.log(getLabResultsFrom);
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          {/* <img src={Arrowleft} alt="arrow-left" /> */}
          <IoArrowBack />
          Forgot something ?
        </div>
      </div>

      <div className="heading2">{title}</div>

      {/* <p>Select your preferred healthcare provider for sample collection, 
    and we will schedule a visit at a date and time convenient for them. Please note that the available time slots may vary depending on the provider's schedule</p> */}

      <div className="sel-test">
        {options?.map((option, index) => {
          return (
            <button key={index} onClick={() => handleOptionClick(option)} className={option === selectedoptions ? "border-[#6EC1F2]  text-[#6EC1F2] !bg-[#044389]" : ""}>
              {option }
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GetResultsFrom;
