// /* eslint-disable @typescript-eslint/no-redeclare */
// import React from "react";
// import { useState } from "react";
// // import CloseIcon from "../../assests/icons/close-icon.svg";
// // import { LuCircleX } from "react-icons/lu";
// import { AiOutlineCloseCircle } from "react-icons/ai";

// // import Arrowleft from "../../assests/icons/Arrowleft.svg";
// import "../../assests/styles/request-lab-service.css";
// import { StepProps } from "./stepsProps";
// import { useNavigate } from "react-router-dom";
// import useStates from "hooks/useStates";
// import { IoArrowBack } from "react-icons/io5";
// type NotEligible = {
//   title: string | null;
//   tip: string | null;
//   CTA: string | null;
// };
// const NotEligible: React.FC<StepProps & NotEligible> = ({
//   onPrevious,
//   title,
//   tip,
//   CTA,
// }) => {
//   const GotoHome = useNavigate();
//   const [isTipVisible, setIsTipVisible] = useState(true); //this state is for managing the tip visibility
//   const handleSubmit = (e: any) => {
//     e.preventDefault();
//     GotoHome("/dashboard") as any;
//   };
//   const handleCloseTip = () => {
//     setIsTipVisible(false); // Hide tip container when close icon is clicked
//   };
//   const {first_name} = useStates()  
//   return (
//     <div className="request-lab-container">
//       <div className="nav-back">
//         <div onClick={onPrevious}>
//           {/* <img src={Arrowleft} alt="arrow-left" /> */}
//           <IoArrowBack />
//           Forgot something ?
//         </div>
//       </div>
//       <div className="heading2">Stay Informed Today ,  <span className="highlight-text">{first_name ??""}</span></div>
     

//       <p>{title}</p>

//       {isTipVisible && (
//         <div className="tip-container">
//           <button className="close-tip" onClick={handleCloseTip}>
//             {/* <img className="img-close-tip" src={CloseIcon} alt="close" /> */}
//             {/* <LuCircleX /> */}
//             <AiOutlineCloseCircle />

//           </button>
//           <h6 className="sel-note-heading">Tip</h6>

//           <p className="sel-note">{tip}</p>
//         </div>
//       )}

//       <div className="sel-test">
//         <button onClick={handleSubmit}>{CTA}</button>
//       </div>
//     </div>
//   );
// };

// export default NotEligible;

/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai"; // Updated import
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import { useNavigate } from "react-router-dom";
import useStates from "hooks/useStates";
import { IoArrowBack } from "react-icons/io5";

type NotEligible = {
  title: string | null;
  tip: string | null;
  CTA: string | null;
};

const NotEligible: React.FC<StepProps & NotEligible> = ({
  onPrevious,
  title,
  tip,
  CTA,
}) => {
  const GotoHome = useNavigate();
  const [isTipVisible, setIsTipVisible] = useState(true);
  const { first_name } = useStates();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    GotoHome("/dashboard") as any;
  };

  const handleCloseTip = () => {
    setIsTipVisible(false);
  };

  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <IoArrowBack />
          Forgot something?
        </div>
      </div>
      <div className="heading2">
        Stay Informed Today, <span className="highlight-text">{first_name ?? ""}</span>
      </div>
      <p>{title}</p>
      {isTipVisible && (
        <div className="tip-container">
          <button className="close-tip" onClick={handleCloseTip}>
            <AiOutlineCloseCircle className="img-close-tip" /> {/* Updated icon */}
          </button>
          <h6 className="sel-note-heading">Tip</h6>
          <p className="sel-note">{tip}</p>
        </div>
      )}
      <div className="sel-test">
        <button onClick={handleSubmit}>{CTA}</button>
      </div>
    </div>
  );
};

export default NotEligible;

