import React, { useState } from 'react';
import RequestService from 'services/requestService';
import ValidationTitle from 'components/shared/validation';
import SuccessValidation from 'components/shared/success';
import '../../../../assests/styles/registration.css';

interface ResetPasswordProps {
  onClose?: () => void;
  className?: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onClose, className = '' }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitted, setFormSubmitStatus] = useState(false);

  const handleContinue = async () => {
    if (email.trim() === '') {
      setError('Please enter your email.');
      return;
    }

    setFormSubmitStatus(true);
    setError('');
    setSuccess('');

    try {
      const response = await RequestService.postMethod('password-reset-link', { email });
      const responseData = response.data.responseData;
      setSuccess(responseData);
    } catch (error) {
      const axiosError = error as { response?: { data: { responseData?: string; errors?: { email: string[] } } } };
      const errorData = axiosError.response?.data;
      if (errorData?.responseData) {
        setError(errorData.responseData);
      } else if (errorData?.errors) {
        setError(errorData.errors.email[0]);
      }
    } finally {
      setFormSubmitStatus(false);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-[9999990] min-h-screen w-screen">
      <div
        className={`bg-white rounded-md shadow-lg font-[Open_Sans] ${className}`}
        style={{
          width: '100%',
          height: '100vh',
          padding: '2%',
          boxSizing: 'border-box'
        }}
      >
        <div className="flex justify-center items-center h-full">
          <div
            className="bg-white rounded-md shadow-md p-6"
            style={{
              width: '100%',
              maxWidth: '465px'
            }}
          >
            <button
              className="flex items-center bg-white text-black font-[Open_Sans] py-2 px-4 rounded-md mb-6 block cursor-pointer w-full max-w-[300px]"
              onClick={onClose}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
              Back to Sign In
            </button>

            <h2 className="text-title-md mb-6 text-title_text_color font-[Open_Sans]">Enter your email to Reset Password</h2>

            <div className="mb-6">
              <label className="block text-gray-600 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                className="border border-gray-300 p-2 rounded-md w-full max-w-[400px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <ValidationTitle title={error} />}
              {success && <SuccessValidation title={success} />}
            </div>
            <button
              className={`bg-[#044389] text-white font-[Open_Sans] py-2 px-4 rounded-md block cursor-pointer w-full max-w-[400px] ${isSubmitted ? 'opacity-50' : ''}`}
              onClick={handleContinue}
              disabled={isSubmitted}
            >
              {isSubmitted ? 'Sending...' : 'Continue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
