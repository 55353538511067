
import { useState, useEffect } from "react";
import InputField from "./inputField";
import useUserInfoForm from "hooks/useUserInfoForm";
import ValidationTitle from "components/shared/validation";
import RequestService from "services/requestService";
import localStorageService from "services/localStorageService";
import { setIsAuthenticated, setUserInfo } from "Features/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../../Store";
import SelectInputField from "components/shared/selectInput";
import PhoneInputComponent from "components/shared/PhoneInputComponent";
import BirthdayForm from "./BirthdayForm";
import Loader from "components/shared/Loader";
import ToastMessage from "components/shared/ToastMesaage";

const MorePatientInfoForm = () => {
  const {
    handleChange,
    errors,
    useUserInfoFormValues,
    setuseUserInfoFormErrors,
    setFormSubmitStatus,
    fillFormValuesInEditMode,
  } = useUserInfoForm();

  const [isSubmitting, setisSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string | null>(null);
  const userInfoData: any = useSelector((state: RootState) => state.auth.userInfo);

  const [toast, setToast] = useState({
    status: "",
    message: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validatePhoneNumber = (phone: string): boolean => {
    const digitsOnly = phone.replace(/\D/g, ''); // Remove non-digit characters
    return digitsOnly.length === 12; // Adjust to the required number of digits
  };

  const validateForm = () => {
    const requiredFields: (keyof typeof useUserInfoFormValues)[] = [
      "first_name",
      "last_name",
      "date_of_birth",
      "gender",
      "phone_number",
      "whatsap_number",
    ];

    let formIsValid = true;
    let errors: Partial<Record<keyof typeof useUserInfoFormValues, string>> = {};

    // Check for required fields
    requiredFields.forEach((field) => {
      if (!useUserInfoFormValues[field]) {
        errors[field] = `${field.replace("_", " ")} is required.`;
        formIsValid = false;
      }
    });

    // Validate phone numbers
    if (!validatePhoneNumber(useUserInfoFormValues.phone_number)) {
      errors.phone_number = "Phone number must be 12 digits.";
      formIsValid = false;
    }

    if (!validatePhoneNumber(useUserInfoFormValues.whatsap_number)) {
      errors.whatsap_number = "WhatsApp number must be 12 digits.";
      formIsValid = false;
    }

    // Check if date of birth includes day, month, and year
    const [year, month, day] = useUserInfoFormValues.date_of_birth.split("-");
    if (!year || !month || !day) {
      errors.date_of_birth = "Please enter a valid date of birth with day, month, and year.";
      formIsValid = false;
    }


    Object.entries(errors).forEach(([key, value]) => {
      setuseUserInfoFormErrors(key as keyof typeof useUserInfoFormValues, value as string);
    });

    if (!formIsValid) {
      setFormErrors("Please fill out all required fields."); // Set overall form error
    } else {
      setFormErrors(null);
    }

    return formIsValid;
  };

  const checkGenderStatus = () => {

    const gender = useUserInfoFormValues.gender;

    return gender === 'Male' || gender === 'Female' || gender === 'Transman' || gender === 'Transwoman';
  }

  const submitData = async () => {

    if (!validateForm()) {
      return;
    }

    setFormSubmitStatus(true);
    setisSubmitting(true);
    const formData = {
      ...useUserInfoFormValues,
      stage: 2,
      id: userInfoData?.id,
      gender: useUserInfoFormValues.gender === "O" ? useUserInfoFormValues.otherGender : useUserInfoFormValues.gender,

    };

    RequestService.postMethod("save-user-info", formData)
      .then(async (response) => {
        const responseData = { ...userInfoData, ...response.data.responseData };
        dispatch(setUserInfo(responseData));
        await localStorageService.setLocalStorageData("user", responseData);

        setFormSubmitStatus(false);
        dispatch(setIsAuthenticated(true));
        setisSubmitting(false);
        navigate("/dashboard");

        setToast({
          ...toast,
          status: "success",
          message: "Information updated",
        });
      })
      .catch((error) => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;
        setisSubmitting(false);

        if (errorData?.responseData) {
          setuseUserInfoFormErrors("e_email", errorData?.responseData);
        }
        if (errorData?.errors) {
          setErrorResponse(errorData.errors);
        }
      });
  };

  const setErrorResponse = (errors: any) => {
    for (let key in errors) {
      setuseUserInfoFormErrors(key as keyof typeof useUserInfoFormValues, errors[key][0]);
    }
  };

  useEffect(() => {
    if (userInfoData && userInfoData.first_name) {
      // const medication = userInfoData.medication ? JSON.parse(userInfoData.medication) : [{ id: "medication0", value: "" }];
      // const allergies = userInfoData.allergies ? JSON.parse(userInfoData.allergies) : [{ id: "allergies0", value: "" }];

      fillFormValuesInEditMode({
        ...userInfoData, ...{
          gender: checkGenderStatus() ? userInfoData.gender : "O",
          otherGender:checkGenderStatus() ? "" : userInfoData.gender,
        }
      });
    }
  }, []);

  return (
    <div className="w-full h-screen p-0 md:p-4 mx-auto max-w-[82%] md:max-w-[36rem] overflow-y-auto" style={{ scrollbarWidth: "none" }}>
      <style>
        {`
          /* Hide scrollbar for Firefox */
          * {
            scrollbar-width: none;
          }
          
          /* Hide scrollbar for Chrome, Safari, and Opera */
          *::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      <ToastMessage status={toast.status} message={toast.message} />
      <div className="flex flex-col gap-5">
        <h3 className="text-[25px] font-[600] md:text-[34px]">Help us to know you more</h3>
      </div>
      <div>
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-[20px] font-[500]">Personal Information</h2>
        </div>
        <div>
          <InputField
            value={useUserInfoFormValues.first_name}
            title="First name"
            placeholder="Florence"
            handleChange={handleChange}
            type="text"
            name="first_name"
            uniqueKey="first_name"
          />
          {errors.first_name && <ValidationTitle title={errors.first_name} />}
          <InputField
            value={useUserInfoFormValues.last_name}
            title="Last Name"
            placeholder="Williams"
            handleChange={handleChange}
            type="text"
            name="last_name"
            uniqueKey="last_name"
          />
          {errors.last_name && <ValidationTitle title={errors.last_name} />}

          <div className="relative">
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">Date of Birth</label>
            <BirthdayForm
              handleChange={(value: string) =>
                handleChange({ target: { name: "date_of_birth", value } })
              }
            />
          </div>
          {errors.date_of_birth && <ValidationTitle title={errors.date_of_birth} />}

          <SelectInputField
            value={useUserInfoFormValues.gender}
            title="Gender"
            placeholder="Select Gender"
            handleChange={handleChange}
            data={[
              { name: "Male", value: "Male" },
              { name: "Female", value: "Female" },
              { name: "Transman", value: "Transman" },
              { name: "Transwoman", value: "Transwoman" },
              { name: "Others (Specify)", value: "O" },
            ]}
            name="gender"
            className="w-[calc(100%-0px)]"
          />

          {useUserInfoFormValues.gender === "O" && (
            <InputField
              value={useUserInfoFormValues.otherGender}
              title="Please specify"
              placeholder="Enter your gender"
              handleChange={handleChange}
              type="text"
              name="otherGender"
              uniqueKey="otherGender"
            />
          )}
          {errors.gender && <ValidationTitle title={errors.gender} />}

          <div>
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">Phone Number</label>
            <PhoneInputComponent
              name="phone_number"
              value={useUserInfoFormValues.phone_number}
              onChange={(value: string) =>
                handleChange({ target: { name: "phone_number", value } })
              }
              error={errors.phone_number}
            />
            {errors.phone_number && <ValidationTitle title={errors.phone_number} />}
          </div>

          <div className="mt-3">
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">WhatsApp Number</label>
            <PhoneInputComponent
              name="whatsap_number"
              value={useUserInfoFormValues.whatsap_number}
              onChange={(value: string) =>
                handleChange({ target: { name: "whatsap_number", value } })
              }
              error={errors.whatsap_number} // Pass the error message from errors
            />
            {errors.whatsap_number && <ValidationTitle title={errors.whatsap_number} />}
          </div>
        </div>
      </div>
      {formErrors && <div className="text-customRed mt-2">{formErrors}</div>} {/* Display form-wide error in red */}
      <button
        style={{ marginTop: "3%" }}
        onClick={submitData}
        className="bg-[#6EC1F2] w-full h-[50px] flex items-center justify-center text-white rounded-lg"
      >
        {isSubmitting ? <Loader /> : "Save"}
      </button>
    </div>
  );
};

export default MorePatientInfoForm;
