import { useState } from "react";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useRequestLabService from "hooks/useRequestLabService";
import { IoArrowBack } from "react-icons/io5";

type LabServiceCollectSampleFromProps = {
  options: string[]; // Array of strings for options
  title: string | null;
  selectCollectSampleFrom: string | null;
};

const LabServiceCollectSampleFrom: React.FC<
  StepProps & LabServiceCollectSampleFromProps
> = ({ onNext, onPrevious, options, title, selectCollectSampleFrom }) => {
  const [collectSampleFrom, setCollectSampleFrom] = useState<string | null>(
    selectCollectSampleFrom || null
  );

  const { setCollectSampleFrom1, dispatch } = useRequestLabService();

  const handleOptionClick = (option: string) => {
    setCollectSampleFrom(option);
    dispatch(setCollectSampleFrom1(option));
    if (onNext) {
      onNext();
    }
  };

  // Updated useEffect to avoid triggering onNext when coming back from the previous step
 
   
  

  // This function should handle going back
  const handleBackClick = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  return (
    <div className="request-lab-container">
      
      <div className="nav-back">
        <div onClick={handleBackClick}>
          {/* <img src={Arrowleft} alt="arrow-left" /> */}
          <IoArrowBack />
          Forgot something ?
        </div>
      </div>

      <div className="heading2">{title || null}</div>

      <div className="sel-test">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(option)}
            className={option === collectSampleFrom ? "border-[#6EC1F2]  text-[#6EC1F2] !bg-[#044389]" : ""}>
          
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LabServiceCollectSampleFrom;
