
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../Store';
import domainPath from '../../../../src/services/domainPath';
import { setIsAuthenticated, setUserInfo } from '../../../Features/auth/auth.slice';
import AuthService from "services/authSevice";
import localStorageService from "services/localStorageService";
import AuthenticatedPatient from "layout/patient/Authenticated";
import NotAuthenticatedPatient from "layout/patient/NotAuthenticated";
import { checkUserSession, setSession } from "services/sessionManagement";

interface useLocationObject {
    pathname: string
}



const HivCarePatientPage: React.FC = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const pathObject: useLocationObject = useLocation();

    const relativePath = domainPath.relativePath(pathObject);

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)


    const isUserAuthenticated = async () => {

        const status = await AuthService.isAuthenticated();

        return status;

    }


    const checkAuthentication = async () => {
        const status = await isUserAuthenticated();
        const userInfo = await localStorageService.getStorageData('user');

        dispatch(setIsAuthenticated(status));


        dispatch(setUserInfo(userInfo));

        if (!status) {
            if (relativePath === '/') {
                navigate('/SignIn');
            } else {
                navigate(relativePath);
            }
        }
        if (status) {

            userSessionActivityCheck();
            
            updateSession();
            
            setInterval(function(){userSessionActivityCheck()}, 120000);
        }
    };

    const isNaveBarRequired = () => {
        const useForm = relativePath.toLocaleLowerCase() !== '/userform';

        const verifyPhone = relativePath.toLocaleLowerCase() !== '/verifyphone';

        return useForm && verifyPhone

    }

    const updateSession=()=>{

        setSession('session');
    }

    const userSessionActivityCheck = async () => {
        const res = await checkUserSession('session');

        
        if (!res) {
            window.location.href="/SignIn";

        }


    }

    useEffect(() => {


        checkAuthentication();

    }, [relativePath])


    return (<div className=''>


        {
            isAuthenticated && isNaveBarRequired() ?
                <AuthenticatedPatient />
                : <NotAuthenticatedPatient />
        }
    </div>)
}

export default HivCarePatientPage;

