/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavBack from "../../assests/icons/arrowleftblack.svg";
import "../../assests/styles/registration.css";
// import HivCareRegLogo from "../../assests/icons/netlifRegLogo.svg";
import HivCareRegLogo from"assets/headerImages/Logo/Landscape/SVG Logos/HIVI CARE Landscape Logos_Black.svg";
import ValidationTitle from "components/shared/validation";
import Loader from "components/shared/Loader";
import { FaCheck, FaTimes } from "react-icons/fa";
import SuccessValidation from "components/shared/success";

interface Register2Props {
  onBack: () => void;
  onNext: () => void;
  handleChange: (e: any) => void;
  registerValues: any;
  errors: any;
  validateRegister: Function;
  success: boolean;
}

const Register2: React.FC<Register2Props> = ({
  onBack,
  handleChange,
  registerValues,
  errors,
  validateRegister,
  onNext,
  success,
}) => {
  const [passwordValidation, setPasswordValidation] = useState<any>([]);
  const [showPasswords, setShowPasswords] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [passwordMatch, setPassWordMatch] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    const isValidated = validateRegister(2);
    validatePassword(registerValues.password);
    if (isValidated) {
      onNext();
    }
  };

  // Password validation function
  const validatePassword = (password: string) => {
    const errors = [
      { message: "At least 8 characters long", isValid: true },
      { message: "At least one lowercase letter", isValid: true },
      { message: "At least one uppercase letter", isValid: true },
      { message: "At least one number", isValid: true },
    ];

    if (password.length < 8) {
      errors.find(
        (error) => error.message === "At least 8 characters long"
      )!.isValid = false;
    }


    if (!/[a-z]/.test(password)) {
      errors.find(
        (error) => error.message === "At least one lowercase letter"
      )!.isValid = false;
    }

    if (!/[A-Z]/.test(password)) {
      errors.find(
        (error) => error.message === "At least one uppercase letter"
      )!.isValid = false;
    }

    if (!/[0-9]/.test(password)) {
      errors.find((error) => error.message === "At least one number")!.isValid =
        false;
    }

    return errors;
  };

  const getColorClass = (isValid: boolean) => {
    return isValid ? "text-customGreen" : "text-customRed";
  };
  const { email, password, password_confirmation } = registerValues;
  // Function to check if the form is valid
  const checkFormValidity = () => {
    if (
      email &&
      password &&
      password_confirmation &&
      password === password_confirmation &&
      passwordValidation?.every((error: any) => error.isValid)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const checkPasswordMisMatch = () => {

    if (!password_confirmation) {
      setPassWordMatch(false);
      return; 
    }
    if (password === password_confirmation) {
      setPassWordMatch(false);
    }
    else{
      setPassWordMatch(true);
    }
  };

  // Watch for changes in password field and validate in real-time
  useEffect(() => {
    const validation = validatePassword(registerValues.password);
    setPasswordValidation(validation);
    checkFormValidity();
  }, [registerValues.password, registerValues.password_confirmation, registerValues.email, checkFormValidity]);
console.log(
  (password_confirmation.length >0)
)
  useEffect(() => {
      checkPasswordMisMatch();
    
  }, [checkPasswordMisMatch, password_confirmation]);
  return (
    <div className="registration-container">
      <div className="registration-form">
        <div className="nav-back" onClick={onBack}>
          <img src={NavBack} alt="nav back" /> Back
        </div>
        <h2>Account security</h2>
        <form>
          <div className="mb-1">
            <label htmlFor="Email Address">Email Address*</label>
            <input
              type="text"
              name="email"
              value={registerValues.email}
              onChange={handleChange}
              id="Email Address"
            />
            {errors.email && <ValidationTitle title={errors.email} />}
          </div>
          <div>
            <label htmlFor="passwordInput">Password*</label>
            <input
              type={showPasswords ? "text" : "password"}
              name="password"
              value={registerValues.password}
              onChange={handleChange} // This will trigger validation as user types
              id="passwordInput"
              role="passwordInput"
              placeholder="At least 8 characters" // Updated placeholder text
            />
            {/* Always display the password validation messages */}
            <div className="text-left">
              <p className="font-semibold mb-2">Your password must contain:</p>
              <ul className="list-none space-y-2">
                {passwordValidation?.map((error: any, index: any) => (
                  <li
                    key={index}
                    className={`flex gap-2 justify-start items-center ${getColorClass(
                      error.isValid
                    )}`}
                  >
                    {error.isValid ? (
                      <FaCheck className="text-green-600" />
                    ) : (
                      <FaTimes className="text-green-600" />
                    )}{" "}
                    <p className="!m-0">{error.message}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <label>Confirm Password*</label>
            <input
              type={showPasswords ? "text" : "password"}
              name="password_confirmation"
              value={registerValues.password_confirmation}
              onChange={handleChange}
            />
            {passwordMatch && (
              <ValidationTitle title="Passwords do not match" />
            )}
            {errors.password && <ValidationTitle title={errors.password} />}
            {errors.custom_error && (
              <ValidationTitle title={errors.custom_error} />
            )}
            {success && (
              <SuccessValidation title="A verification link has been sent to your email" />
            )}
          </div>

          <div className="show-password-container">
            <label>
              <input
                type="checkbox"
                checked={showPasswords}
                onChange={() => setShowPasswords(!showPasswords)}
              />
              Show Password
            </label>
          </div>

          {/* Button to create account with disabled condition */}
          <button
            type="button"
            disabled={!isFormValid}
            onClick={handleSubmit}
            className={isFormValid ? "active-button" : "inactive-button"}
          >
            {registerValues.is_form_submitted ? (
              <div className="flex justify-center items-center w-full h-full m-0">
                <Loader />
              </div>
            ) : (
              "Create Account"
            )}
          </button>
        </form>
        <p
          className="already cursor-pointer"
          onClick={() => navigate("/SignIn")}
        >
          Already have an account? <span>Sign in</span>
        </p>
        <div className="cop-right">
          <ul>
            <li>
              <div>© 2024 Hiv Care </div>
            </li>
            <li>
              <div>About Us</div>
            </li>
            <li>
              <div>Terms of Service</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
          </ul>
        </div>
      </div>

      <div className="registration-image">
        <div className="overlay">
          <h3>
            See your health care <br /> anytime, anywhere
          </h3>
          <img src={HivCareRegLogo} alt="reg icon" />
        </div>
      </div>
    </div>
  );
};

export default Register2;
