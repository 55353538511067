import Confetti from "react-confetti";
import SuccessLogo from "../../assests/icons/ion_checkmark-circle-outline.svg";
import { useNavigate } from "react-router-dom";
import { setCreateSuccess } from "Features/app/RequestLabService/HivCare.slice";
import { useDispatch } from "react-redux";
import "../../assests/styles/SuccessPage.css"; 

type successtypes = {
  onNext: () => void;
  onPrevious: () => void;
};

// eslint-disable-next-line no-empty-pattern
export default function OnSuccessPage({ }: successtypes) {
  const dispatch = useDispatch();
  const goTo = useNavigate();

  const handleChange = (e: any) => {
    e.preventDefault();
    dispatch(setCreateSuccess(false));
    goTo("/dashboard");
    
  };

  return (
    <div className="success-page">
      <Confetti width={800} height={600} numberOfPieces={200} recycle={true} gravity={0.1} />
      <div className="success-card">
        <div className="success-logo-container">
          <img src={SuccessLogo} alt="success" className="success-logo" />
        </div>
        <h2>Successful!</h2>
        <p>Thank you for choosing Hiv Care for your healthcare needs. We’ve sent a confirmation email with all the details to your inbox..</p>
        <button onClick={handleChange}>Okay</button>
      </div>
    </div>
  );
}
