import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdDashboard, MdLocalHospital, MdExitToApp } from 'react-icons/md';
import logo from '../../../src/assets/headerImages/Logo/Landscape/SVG Logos/HIVI CARE Landscape Logos_White copy.svg';
import hivicon from '../../../src/assests/icons/hiv.svg';
import '../../assets/header.css';
import Loader from 'components/shared/Loader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserInfo, setIsAuthenticated } from '../../Features/auth/auth.slice';
import localStorageService from 'services/localStorageService';
import RequestService from 'services/requestService';

const LargeScreenNavBar: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1000);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    setIsLoading(true);
    await localStorageService.clearStorage('user');

    setTimeout(() => {
      dispatch(setUserInfo({}));
      dispatch(setIsAuthenticated(false));
      navigate('/SignIn');
      setIsLoading(false);
    }, 5000);
    await RequestService.getMethod('logout');

    dispatch(setUserInfo({}));
    dispatch(setIsAuthenticated(false));
    navigate('/SignIn');
    setIsLoading(false);
  };

  const handleDashboardClick = () => {
    if (!isLargeScreen) {
      setIsSidebarOpen(false);
    }
    if (location.pathname !== '/dashboard') {
      navigate('/dashboard');
    }
  };

  const handleHivCareClick = () => {
   
    if (location.pathname === '/hiv-care') {
      window.location.reload(); 
    } else {
      if (!isLargeScreen) {
        setIsSidebarOpen(false);
      }
      navigate('/hiv-care');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {!isLargeScreen && (
        <button className="menu-icon" onClick={toggleSidebar}>
          {/* ☰ */}
        </button>
      )}
      {isSidebarOpen && !isLargeScreen && <div className="overlays" onClick={toggleSidebar}></div>}
      <div className={`sidebar ${isLargeScreen ? 'large-screen' : isSidebarOpen ? 'open' : ''}`} id="side">
        <img src={logo} alt="Logo" onClick={()=>navigate("/")} className="sidebar-logo cursor-pointer" />

        <div className="middle_content">
          <h1 className="sidebar-title">Get Care</h1>

          {/* Dashboard */}
          <Link to="/dashboard" onClick={handleDashboardClick} className={location.pathname === '/dashboard' ? 'active' : ''}>
            <NavItem icon={<MdDashboard />} text="Dashboard" />
          </Link>

          {/* Get HIV Care */}
          <Link to="/hiv-care" onClick={handleHivCareClick}>
            <button className="see-doctor-btn">
              <img src={hivicon} alt="Get HIV Care" className="nav-icon" />
              Get HIV Care
            </button>
          </Link>
        </div>

        <div className="secure-info">
          <div className="secure-info-content">
            <h2 className="secure-info-title">Secure your health information</h2>
            <button onClick={handleLogout} className="logout-btn">
              {isLoading ? (
                <div className="flex justify-center items-center w-full h-full m-0"><Loader /></div>
              ) : (
                'Sign out'
              )}
            </button>
          </div>
        </div>
      </div>

      {!isLargeScreen && (
        <div className="bottom-nav">
          <Link to="/dashboard" className={`bottom-nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`} onClick={handleDashboardClick}>
            <MdDashboard className="bottom-nav-icon" />
            <span className="bottom-nav-text">Dashboard</span>
          </Link>
          <Link to="/hiv-care" className="bottom-nav-item get-hiv-care" onClick={handleHivCareClick}>
            <div className="bottom-nav-icon">
              <MdLocalHospital size={24} color="#fff" />
            </div>
          </Link>
          <button onClick={handleLogout} className="bottom-nav-item">
            {isLoading ? (
              <div className="flex justify-center items-center w-full h-full m-0"><Loader /></div>
            ) : (
              <>
                <MdExitToApp className="bottom-nav-icon" />
                <span className="bottom-nav-text">Log Out</span>
              </>
            )}
          </button>
        </div>
      )}
    </>
  );
};

interface NavItemProps {
  icon: React.ReactNode;
  text: string;
}

const NavItem: React.FC<NavItemProps> = ({ icon, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={`nav-item ${isHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="nav-icon">{icon}</div>
      <span className="nav-text">{text}</span>
    </div>
  );
};

export default LargeScreenNavBar;
