import { useState } from "react";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
// import CloseIcon from "../../assests/icons/close-icon.svg";
import '../../assests/styles/request-lab-service.css';
import {StepProps} from './stepsProps';
import useStates from "hooks/useStates";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";
const RequestLabService4: React.FC<StepProps> = ({onNext,onPrevious}) => {
    const {first_name} = useStates()
    const [isTipVisible, setIsTipVisible] = useState(true);//this state is for managing the tip visibility

    const handleCloseTip = () => {
        setIsTipVisible(false); // Hide tip container when close icon is clicked
    };

    return (
        <div className="request-lab-container">
            <div className="nav-back">
                <div onClick={onPrevious}>
                    {/* <img src={Arrowleft} alt="arrow-left" /> */}
                    <IoArrowBack />
                Forgot something ?</div>
            </div>
            <div className="heading2">Step Towards Wellness, <span className="highlight-text">{first_name || null}!</span></div>

            <p>You’re eligible for HIV testing. Now is the perfect time to take the proactive step and get tested</p>

            {isTipVisible && (
                   <div className="tip-container">
                    <button className="close-tip" onClick={handleCloseTip}>
                        {/* <img className="img-close-tip" src={CloseIcon} alt="close" /> */}
                        <AiOutlineCloseCircle className="img-close-tip" /> {/* Updated icon */}

                    </button>
                   <h6 className="sel-note-heading">Tip</h6>
                   
               <p className="sel-note">
               HIV/AIDS is a crucial global health issue affecting many. With the right treatment, people with HIV can lead long and healthy lives despite the seriousness of the condition. Don't delay—get tested today and take charge of your well-being
               </p>
   
               </div>

            )}

            <div className="sel-test">
                <button onClick={onNext}>Get Tested Now</button>
                {/* <button onClick={onNext}>Self Testing</button> */}
            </div>
    
        </div>

    )
}
export default RequestLabService4;