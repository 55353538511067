


const domain = 'https://netlifeapi.netlife.cc/backend/public/api/patient/';


const patientConstants = {

    api_domain: domain
}


export default patientConstants;
