import React, { useState, useRef, useEffect } from 'react';
import Header from "layout/patient/header";

import MobileHeader from 'layout/patient/MobileHeader';
import SavedServiceMobile from 'views/patients/pages/auth/SavedServiceMobile';

const SavedServiceCenter: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleRemoveClick = () => {
    console.log('Navigating to remove functionality');
  };

  const handleGetDirectionClick = () => {
    console.log('Navigating to specific directory');
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(true);
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  const handleLogout = () => {
    console.log('Logged out');
    // Add actual logout logic here
  };

  return (
    <div>
      <Header />
      
      <div className="block md:hidden">
      
        <SavedServiceMobile />
        <MobileHeader onOpenSidebar={handleSidebarOpen} onLogout={handleLogout} />
      </div>
      {/* Corrected style attribute format */}
      <div style={{ marginTop: '4px',marginRight: '10%%', whiteSpace: 'nowrap',marginLeft: '10%%' }}>
        <div className="hidden md:block">
        <div >
          <div className="md:w-auto">
            <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
            <div className="w-80% h-auto gap-0 border-2 border-[#6EC1F2] rounded-lg bg-blue-100 p-4">
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
                <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                  Remove
                </div>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
                <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#6EC1F2' }} onClick={handleGetDirectionClick}>
                  Get Direction
                </div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
        <div className="hidden md:block" >
          <div className="md:w-auto" >
            <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
            <div className="w-80% h-auto gap-0 border-2 border-[#6EC1F2] rounded-lg bg-blue-100 p-4">
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
                <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                  Remove
                </div>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
                <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#6EC1F2' }} onClick={handleGetDirectionClick}>
                  Get Direction
                </div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          </div>
        <div className="hidden md:block" >
          <div className="md:w-auto" >
            <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
            <div className="w-80% h-auto gap-0 border-2 border-[#6EC1F2] rounded-lg bg-blue-100 p-4">
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
                <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                  Remove
                </div>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
                <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#6EC1F2' }} onClick={handleGetDirectionClick}>
                  Get Direction
                </div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          </div>
        <div className="hidden md:block" >
          <div className="md:w-auto" >
            <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
            <div className="w-80% h-auto gap-0 border-2 border-[#6EC1F2] rounded-lg bg-blue-100 p-4">
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
                <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                  Remove
                </div>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
                <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#6EC1F2' }} onClick={handleGetDirectionClick}>
                  Get Direction
                </div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
        <div className="hidden md:block" >
          <div className="md:w-auto" >
            <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
            <div className="w-80% h-auto gap-0 border-2 border-[#6EC1F2] rounded-lg bg-blue-100 p-4">
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
                <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                  Remove
                </div>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
                <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#6EC1F2' }} onClick={handleGetDirectionClick}>
                  Get Direction
                </div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
              </div>
              <div className="p-4">
                <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default SavedServiceCenter;
