import React from 'react';
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
import Questions from "./Question";
import '../../assests/styles/request-lab-service.css';
import { StepProps } from './stepsProps';
import useStates from 'hooks/useStates';
import { IoArrowBack } from 'react-icons/io5';
type LabServiceQuestionProps = {
introduction:null
question:null
} 
const LabServiceQuestions: React.FC<StepProps & LabServiceQuestionProps> = ({ onNext, onPrevious, onNotEligible ,introduction ,question}) => {
  const {first_name} = useStates()
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          {/* <img src={Arrowleft} alt="arrow-left" /> */}
          <IoArrowBack />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">Welcome, <span className="highlight-text">{first_name || null}</span></div>
     
      <p>{introduction}</p>
      <div className="heading3">Screening Questions</div>
      <Questions onNext={onNext} onPrevious={onPrevious} onNotEligible={onNotEligible} questions={question} />
    </div>
  );
};

export default LabServiceQuestions;
